// Replace placeholders with your actual values
// const restaurantId = "269794";
const apiKey = "o43mYUZRlCrO9mMQMX";
// const apiKey = "Zd9wpfxj6tVBqm83oJ";

export const fetchMenu = async () => {
  try {
    const url = "/pos/menu"; // Use the proxy prefix
    const headers = {
      Authorization: `Bearer ${apiKey}`,
      Accept: "application/json",
      "Glf-Api-Version": "2",
    };

    const response = await fetch(url, {
      method: "GET", // Assuming a GET request, change to 'POST' if required
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP Error ${response.status}`);
    }

    const menuData = await response.json();
    console.log(menuData); // Do something with your menu data
  } catch (error) {
    console.error("Error fetching menu:", error);
  }
};
