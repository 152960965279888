import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import GloriaFoodButtons from "./GloriaFoodButtons";
import Carousel from "fade-carousel";
import { useQuery } from "@apollo/client";
import { GET_PROMOTIONS } from "../queries";
import { constants } from "../design/const";

const imageStyle = {
  height: "auto",
  width: "100%",
  justifySelf: "center",
};
const Slideshow = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const slidesShowHeight = matches ? "50vw" : "100vw";
  const slidesShowMaxHeight = matches ? "512px" : "100vw";

  const { loading, error, data } = useQuery(GET_PROMOTIONS);

  // if (loading)
  //   return (
  //     <Container sx={{ height: slidesShowHeight, width: "100%" }}>
  //       <p>Loading...</p>
  //     </Container>
  //   );
  // if (error)
  //   return (
  //     <Container sx={{ height: slidesShowHeight, width: "100%" }}>
  //       <p>Error getting promotions</p>
  //     </Container>
  //   );

  // const promotionsFound = Boolean(data?.promotions.edges.length);
  // if (!promotionsFound) return <p>No Promotions</p>;

  console.log("slides", data?.promotions.edges);

  const slides = [
    {
      mobile: "/slides/beer-nuggets-mobile.jpg",
      desktop: "/slides/beer-nugg2.jpg",
      altText: "Beer Nuggets",
    },
    {
      mobile: "/slides/hotdogs-mobile.jpg",
      desktop: "/slides/hotdogs-wide.jpg",
      altText: "Hotdogs",
    },
    {
      mobile: "/slides/godfather-mobile2.jpg",
      desktop: "/slides/godfather.jpg",
      altText: "The Godfather",
    },
    {
      mobile: "/slides/maxwell-mobile.jpg",
      desktop: "/slides/maxwell-desktop.jpg",
      altText: "Maxwell Street Hotdog",
    },
    {
      mobile: "/slides/mozza-mobile.jpg",
      desktop: "/slides/mozza.jpg",
      altText: "Mozzarella Sticks",
    },
    {
      mobile: "/slides/shrimpp-mobile.jpg",
      desktop: "/slides/shrimpp.jpg",
      altText: "Garlic Shrimp Pasta",
    },
  ];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "relative",
        height: slidesShowHeight,
        maxHeight: slidesShowMaxHeight,
      }}
    >
      <GloriaFoodButtons />

      <Container sx={{ height: "100%", width: "100%" }}>
        <Carousel
          divStyle={{
            borderBottomLeftRadius: constants.borderRadius,
            borderBottomRightRadius: constants.borderRadius,
            overflow: "hidden",
            height: slidesShowHeight,
            maxWidth: "1024px",
            maxHeight: slidesShowMaxHeight,
            margin: "0 auto",
            alignItems: "center",
            justifyContent: "center",
          }}
          delay={5000}
          mode={"fade"}
          dots={false}
        >
          {/* {data.promotions.edges.map((promo: any, index: number) => (
            <div key={index} style={imageStyle}>
              <SlideshowImg
                src={
                  matches
                    ? promo.node.featuredImage.node.mediaDetails.sizes[1]
                        .sourceUrl
                    : promo.node.promotion_mobile_image.promotionMobileImage &&
                      promo.node.promotion_mobile_image.promotionMobileImage
                        .mediaDetails.sizes[1].sourceUrl !== null
                    ? promo.node.promotion_mobile_image.promotionMobileImage
                        .mediaDetails.sizes[1].sourceUrl
                    : promo.node.featuredImage.node.mediaDetails.sizes[3]
                        .sourceUrl
                }
                alt={promo.node.featuredImage.node.altText}
              />
            </div>
          ))} */}

          {slides.map((promo: any, index: number) => (
            <div key={index} style={imageStyle}>
              <SlideshowImg
                src={matches ? promo.desktop : promo.mobile && promo.mobile}
                alt={promo.altText}
              />
            </div>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};
export default Slideshow;

const SlideshowImg = styled("img")(({ theme }) => ({
  flex: 1,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  [theme.breakpoints.up("md")]: {
    objectFit: "contain",
  },
}));

const Container = styled(Box)({
  "& div div div div:last-child svg": {
    display: "none",
  },
  "& div div div div:first-child svg": {
    cursor: "pointer",
  },
});
